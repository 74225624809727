import React from 'react';
import Title from "components/global/title";
import default_data from "values/default";


const OverviewCard = ({ activePlan, activePlanStatus, activePrice }) => {

  return (
    <div className="StatisticsCard flex flex-col md:flex-row w-full p-4 bg-white rounded-xl shadow justify-start items-center gap-2.5">
      <div className="Frame45 flex p-[8px] flex-col items-start justify-center gap-[8px] flex-[1_0_0] self-stretch ">
        <Title
          text="My Plan Details"
          className="text-[16px] md:text-[25px]"
        />
      </div>
      <div className="Frame45 flex p-[8px] flex-col items-start gap-[16px] flex-[1_0_0] self-stretch">
        <div className="Frame88 items-center w-full flex flex-col md:flex-row justify-between gap-[8px] lg:gap-[4rem]">
          <div className="Frame14183 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Plan Status</div>
            <div className={`flex px-[8px] py-[4px] items-start gap-[10px] text-[14px] not-italic font-semibold leading-[140%] rounded-[8px]
              ${activePlanStatus === default_data.subscription_status.active ? 'bg-[#CDF3B7] text-[#375525]' :
              activePlanStatus === default_data.subscription_status.pending ? 'bg-[#CACCE4] text-[#2E3570]' : 'bg-[#CACCE4] text-[#2E3570]'}`}>
              {activePlanStatus}
            </div>
          </div>
          <div className="Frame14183 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Plan</div>
            <div className="Text text-[#283567] text-[14px] not-italic font-semibold leading-[140%]">{activePlan}</div>
          </div>
          <div className="Frame14184 justify-between w-full md:w-auto flex md:flex-col items-start gap-[8px] flex-[1_0_0]">
            <div className="Text text-[#283567] text-[14px] not-italic font-normal leading-[140%]">Price</div>
            <div className="Text text-[#283567] text-[14px] not-italic font-semibold leading-[140%]">${activePrice} /month</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewCard;
